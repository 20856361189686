import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import { IoIosCloudDone } from "react-icons/io";
import { IoCheckmarkCircle, IoShieldCheckmark } from "react-icons/io5";
import styled from "styled-components";

import { CallToAction, ConnectionButton } from "../components/site/";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

import DroppahPlusXero from "../images/xero/DroppahPlusXero.svg";
import DroppahPlusXeroFlow from "../images/xero/DroppahPlusXeroFlow.svg";

const NumberBadge = styled.div`
  background-color: ${(props) => props.theme.colours.deeppurple};
  color: ${(props) => props.theme.colours.white};
  border-radius: 35px;
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  line-height: 35px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  text-align: center;
  user-select: none;
  margin-top: -7px;
`;

const Xero = (props) => {
  const FAQs = [
    {
      question: "Can I sign up to Droppah using my Xero Payroll details?",
      answer:
        "Yes! You can use your Xero credentials to create a Droppah account. This will automatically integrate your Droppah account with Xero and you can log in using these same credentials.",
    },
    {
      question: "Do you have an integration with Xero Payroll NZ?",
      answer: [
        <span>
          No. In New Zealand, Droppah has a deep two-way integration with
          award-winning software, PayHero.{" "}
        </span>,
        <Link css={{ color: "#fff" }} to="/payhero">
          Learn more here
        </Link>,
        <span>.</span>,
      ],
    },
    {
      question: "Does Droppah have a mobile app?",
      answer:
        "It sure does! Your team can use the employee mobile app to view their roster and request shift swaps.",
    },
    {
      question: "What costs are associated with using Droppah?",
      answer: [
        <span>
          Great news! Droppah is free for businesses with 10 or fewer employees.
          For larger businesses, check out the{" "}
        </span>,
        <Link css={{ color: "#fff" }} to="/pricing">
          Droppah pricing here
        </Link>,
        <span>.</span>,
      ],
    },
    {
      question: "How can I get help with Droppah?",
      answer: [
        <span>Check out Droppah’s extensive </span>,
        <a
          css={{ color: "#fff" }}
          href="https://support.droppah.com/hc/en-us"
          target="_blank"
        >
          support centre
        </a>,
        <span>
          {" "}
          to find any answer under the sun. Otherwise, our awesome support team
          are only an email away.
        </span>,
      ],
    },
  ];

  return (
    <Layout>
      <Seo
        title="Droppah + Xero Integration | Synced-up Rosters and Payroll"
        description="Integrate Droppah with Xero Payroll Australia to automatically send employee time and data to & fro."
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={30} centerOnMobile>
              <img src={DroppahPlusXero} alt="Droppah + Xero" height="55" />
              <Box stackGap={10}>
                <h1>Slash your admin with synced-up rosters and payroll</h1>
                <h4>
                  Integrate Droppah with Xero Payroll Australia to automatically
                  send employee time and data to & fro.
                </h4>
              </Box>
              <Box css={{ display: "table" }} stackGap={15}>
                <FlexButtons>
                  <ConnectionButton connection="Xero" product="Droppah" />
                  <Button to="/signup" className="primary -lg gtm-cta">
                    Sign up with email
                  </Button>
                </FlexButtons>
                <p
                  css={{ fontSize: "1rem", opacity: "0.5" }}
                  className="-textCenter"
                >
                  Droppah is free for businesses with up to 10 employees
                </p>
              </Box>
            </Box>
            <Box size={50}>
              <Image
                filename="Droppah_CalendarAndTime.png"
                alt="Droppah + Xero Integration"
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="deeppurple">
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoCheckmarkCircle />
                <br />
                Build your ideal roster in a click
              </h3>
              <p>
                Your whole team will appreciate Droppah’s AI auto-scheduler.
                Create a roster that accounts for their skills, shift
                preferences and wage costs.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Complete the flow with polished payroll
              </h3>
              <p>
                Pay staff and report payroll details to the ATO. Automate tax,
                pay and super calculations - no hassle required.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCloudDone />
                <br />
                Teamwork makes the dream work
              </h3>
              <p>
                Align employee info and send completed time from Droppah to Xero
                Payroll to pay your employees right.
              </p>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper className="-textCenter">
          <h2>How does the Xero integration work?</h2>
          <Row stackGap={60}>
            <Box size={33} stackGap={25}>
              <NumberBadge>1</NumberBadge>
              <div>
                <h3>Merge</h3>
                <p>
                  Get it going by signing into Xero in the Droppah Settings. Or,
                  sign up with Xero and Droppah will integrate automatically.
                </p>
              </div>
              <Image
                filename="XeroInt_Merge.png"
                alt="Droppah"
                addShadow
                centerImage
              />
            </Box>
            <Box size={33} stackGap={25}>
              <NumberBadge>2</NumberBadge>
              <div>
                <h3>Sync</h3>
                <p>
                  Connect your Xero Employee Details to People in Droppah and
                  your Xero Pay Items to Skills in Droppah.
                </p>
              </div>
              <Image
                filename="XeroInt_Sync.png"
                alt="Droppah"
                addShadow
                centerImage
              />
            </Box>
            <Box size={33} stackGap={25}>
              <NumberBadge>3</NumberBadge>
              <div>
                <h3>Send</h3>
                <p>
                  Complete your roster in Droppah and send timesheets to Xero
                  Payroll for easy employee pay processing.
                </p>
              </div>
              <Image
                filename="XeroInt_Send.png"
                alt="Droppah"
                addShadow
                centerImage
              />
            </Box>
          </Row>
          <img
            src={DroppahPlusXeroFlow}
            alt="Droppah + Xero Integration Flow"
            css={{ width: "100%", maxWidth: "700px" }}
            className="-center"
          />
        </Wrapper>
      </Container>
      <Container bg="midnight">
        <Wrapper maxWidth={650}>
          <h2 className="-textCenter">FAQs</h2>
          <Box stackGap={50}>
            {FAQs.map((item, i) => {
              return (
                <Box key={i}>
                  <h4 className="-fontBold">{item.question}</h4>
                  <p>{item.answer}</p>
                </Box>
              );
            })}
          </Box>
        </Wrapper>
      </Container>
      <CallToAction
        title="Connect Droppah & Xero Payroll at no cost today"
        description="Enjoy an epic rostering system and get your employees paid."
        buttonText="Sign me up to Droppah!"
        hideDemoButton
        showXeroButton
      />
    </Layout>
  );
};

export default Xero;
